@font-face {
  font-family: "gtwalsheim";
  font-weight: 400;
  font-style: normal;
  src: url(/static/media/gt-walsheim-web.f55aab9d.eot);
  src: url(/static/media/gt-walsheim-web.f55aab9d.eot?#iefix) format("embedded-opentype"), url(/static/media/gt-walsheim-web.6a68531d.woff) format("woff"), url(/static/media/gt-walsheim-web.a78cebcc.ttf) format("truetype"), url(/static/media/gt-walsheim-web.acc828e3.svg#gtwalsheim) format("svg")
}

@font-face {
  font-family: "gtwalsheim";
  font-weight: 500;
  font-style: normal;
  src: url(/static/media/gt-walsheim-medium-web.871771d4.eot);
  src: url(/static/media/gt-walsheim-medium-web.871771d4.eot?#iefix) format("embedded-opentype"), url(/static/media/gt-walsheim-medium-web.42db0bce.woff) format("woff"), url(/static/media/gt-walsheim-medium-web.1821054e.ttf) format("truetype"), url(/static/media/gt-walsheim-medium-web.738152b0.svg#gtwalsheim) format("svg")
}

@font-face {
  font-family: "gtwalsheim";
  font-weight: 600;
  font-style: bold;
  src: url(/static/media/gt-walsheim-bold-web.e41a3814.eot);
  src: url(/static/media/gt-walsheim-bold-web.e41a3814.eot?#iefix) format("embedded-opentype"), url(/static/media/gt-walsheim-bold-web.e7ba59f7.woff) format("woff"), url(/static/media/gt-walsheim-bold-web.61ee4110.ttf) format("truetype"), url(/static/media/gt-walsheim-bold-web.3dd2ec54.svg#gtwalsheim) format("svg")
}

*, body, html {
  margin: 0;
  padding: 0;
  font-family: 'gtwalsheim' !important;
  font-weight: normal;
  font-size: 16px;
}

h1 {
  font-weight: 600;
  font-size: 54px;
  line-height: 57px;
  color: #5F6762;
}

h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 46px;
  color: #5F6762;
}

h3 {
  font-weight: 600;
  font-size: 28px;
}

p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A6A6A6;
}

a.button.coup, button.button.coup {
  box-shadow: 0px 12px 27px rgba(0,0,0,0.18585);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-family: 'gtwalsheim';
  width: 240px;
  padding: 22px 24px;
  border: none;
  line-height: 4px;
  transition: background .2s;
}

a.button.coup.primary, button.button.coup.primary {
  background-color: #2CC18B;
  color: white;
}

a.button.coup.primary:hover, button.button.coup.primary:hover {
  color: white;
  background-color: #24b681;
}

a.button.coup.secondary, button.button.coup.secondary {
  background-color: #ffffff;
  color: #2CC18B;
}

a.button.coup.secondary:hover, button.button.coup.secondary:hover {
  color: #2CC18B;
  background-color: #fcfcfc;
}

